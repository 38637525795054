import React from 'react';
import { useSwiper } from 'swiper/react';

const SwiperButtons = () => {
    const swiper = useSwiper();
    const [currentIndex, setCurrentIndex] = React.useState(1);
    const prev = () => {
        swiper.slidePrev();
        setCurrentIndex(swiper.realIndex + 1);
    }
    const next = () => {
        swiper.slideNext();
        setCurrentIndex(swiper.realIndex + 1);
    }
    return (
        <div className="swiper-buttons">
            <p>{currentIndex}/5 &nbsp;</p>
            <progress value={currentIndex} max={5}></progress>
            <button className="button-primary" onClick={prev}>&#60;</button>
            <button className="button-primary" onClick={next}>&#62;</button>
        </div>
    )
}

export default SwiperButtons