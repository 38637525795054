import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Servicios from "./components/Servicios";
import Home from "./components/Home";
import Nosotros from "./components/Nosotros";
import Productos from "./components/Productos";
import Contacto from "./components/Contacto";
import ServiciosPaquetes from "./components/ServiciosConsultoria";
import SeguridadControl from "./components/SeguridadControl";
import ServiciosAuxiliares from "./components/ServiciosAuxiliares";
import Footer from "./components/Footer";
import Gps from "./components/Gps";
import Sigas from "./components/Sigas";
import ScrollToTop from "./components/ScrollToTop";
import Alarmas from "./components/Alarmas";
import CCTV from "./components/CCTV";
import Acceso from "./components/Acceso";
import { ProductDetail } from "./components/ProductDetail";
import GpsProductos from "./components/GpsProductos";
import Seguridad from "./components/Seguridad";
import Red from "./components/Red";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <NavBar />
        <Switch>
          <Route path="/inicio"></Route>
          <Route path="/servicios">
            <Servicios />
          </Route>
          <Route path="/nosotros">
            <Nosotros />
          </Route>
          <Route path="/productos">
            <Productos />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
          <Route path="/seg-control">
            <SeguridadControl />
          </Route>
          <Route path="/serv-auxiliar">
            <ServiciosAuxiliares />
          </Route>
          <Route path="/serv-consultoria">
            <ServiciosPaquetes />
          </Route>
          <Route path="/gps-productos">
            <GpsProductos />
          </Route>
          <Route path="/gps">
            <Gps />
          </Route>
          <Route path="/sigas">
            <Sigas />
          </Route>
          <Route path="/alarmas">
            <Alarmas />
          </Route>
          <Route path="/cctv">
            <CCTV />
          </Route>
          <Route path="/acceso">
            <Acceso />
          </Route>
          <Route path="/seguridad">
            <Seguridad />
          </Route>
          <Route path="/red">
            <Red />
          </Route>
          <Route path="/product-detail/:id">
            <ProductDetail />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
