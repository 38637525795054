import React from "react";

import SeguridadImagenes from "../img/Servicios/seguridad-imagenes.jpg";
import MonitoreoAlarmas from "../img/Servicios/monitoreo-alarmas.jpg";
import Rastreo from "../img/Servicios/rastreo.png";
import MonitoreoXs from "../img/Monitoreo/remoto-xs.webp";
import MonitoreoSm from "../img/Monitoreo/remoto-sm.webp";
import MonitoreoMd from "../img/Monitoreo/remoto-md.webp";
import MonitoreoLg from "../img/Monitoreo/remoto-lg.webp";
import MonitoreoXl from "../img/Monitoreo/remoto-xl.webp";
import Monitoreo from "../img/Monitoreo/remoto-xl.png";

import {
  BiHome,
  BiCctv,
  BiCurrentLocation,
  BiMap,
  BiCar,
  BiSupport,
  BiBroadcast,
  BiLaptop,
} from "react-icons/bi";

const ServiciosAuxiliares = () => {
  return (
    <div className="main l-jic">
      <div className="servicios__intro swiper-slide polygon polygon-dark-bg">
        <div className="content-img">
          {/* <img src={Monitoreo} alt="" /> */}
          <picture>
            <source
              srcSet={`${MonitoreoXs} 320w, ${MonitoreoSm} 480w, ${MonitoreoMd} 768w, ${MonitoreoLg} 1200w, ${MonitoreoXl} 1920w`}
              type="image/webp"
            />
            <img src={Monitoreo} alt="" />
          </picture>
        </div>
        <div className="polygon-content polygon-bg-dark">
          <h1>Servicios inteligentes e innovadores</h1>
          <p>
            Elige el servicio apropiado para tu necesidad o solicita un
            servicio personalizado
          </p>
        </div>
      </div>

      <div className="section__title l-bg-gray">
        <h2 className="l-text-light">Nuestras Opciones</h2>
      </div>

      <div className="servicios__container l-mw-90">
        <div className="servicios-aux-list">
          <div className="card">
            <div className="card__content">
              <div className="card-new-header">
                <div className="card-new-icons">
                  <BiCctv />
                  <BiSupport />
                </div>
                <p className="card-title-big">
                  Seguridad por imágenes, monitoreo 24/7
                </p>
              </div>

              <ul>
                <li>
                  Optimización e integración de equipos y cámaras de vigilancia
                  existentes (cctv) a nuestra plataforma de monitoreo 24/7.
                </li>
                <li>
                  Instalación e integración de nuevos equipos y cámaras de
                  vigilancia (cctv).
                </li>
                <li>
                  Monitoreo remoto de cámaras de vigilancia (cctv),
                  comunicaciones y alertas tempranas de situaciones de
                  inseguridad y riesgo.
                </li>
                <li>
                  Asistencia inmediata en apoyo a la respuesta de seguridad.
                </li>
                <li>Gestión de asistencia a la autoridad competente.</li>
              </ul>

              <span className="btn btn-red mt-2">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el Servicio Seguridad por Imágenes, Monitoreo 24/7"} target="_blank" rel="noreferrer">
                  Solicitar este paquete
                </a>
              </span>
            </div>
          </div>

          <div className="img-grid">
            <img src={SeguridadImagenes} alt="" />
            <img src={Monitoreo} alt="" />
          </div>

          <div className="img-grid">
            <img src={MonitoreoAlarmas} alt="" />
            <img src={Monitoreo} alt="" />
          </div>

          <div className="card relative">
            <div className="card__content">
              <div className="card-new-header">
                <div className="card-new-icons">
                  <div>
                    <BiBroadcast />
                    <BiHome />
                  </div>
                  <div>
                    <BiSupport />
                    <BiCctv />
                  </div>
                </div>
                <p className="card-title-big">
                  Monitoreo de Alarmas 24/7 para Empresas y Domicilios
                </p>
              </div>

              <ul className="mt-1">
                <li>
                  Optimización e integración de alarmas
                  existentes a nuestra plataforma 24/7.
                </li>
                <li>Instalación e integración de nuevos sistemas de alarmas.</li>
                <li>
                  Monitoreo 24/7, comunicaciñon y alerta temprana de señales de alarmas recibidas.
                </li>
                <li>
                  Asistencia inmediata en apoyo a la respuesta de seguridad.
                </li>
                <li>Gestión de asistencia a la autoridad competente.</li>
              </ul>

              <span className="btn btn-red mt-2">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el Servicio Monitoreo de Alarmas 24/7 para Empresas y Domicilios"} target="_blank" rel="noreferrer">
                  Solicitar este paquete
                </a>
              </span>
            </div>
          </div>

          <div className="card relative">
            <div className="card__content">
              <div className="card-new-header">
                <div className="card-new-icons">
                  <div>
                    <BiCurrentLocation />
                    <BiCar />
                  </div>
                  <div>
                    <BiLaptop />
                    <BiMap />
                  </div>
                </div>
                <p className="card-title-big">
                  GPS - Rastreo y Localización 24/7
                </p>
              </div>

              <ul>
                <li>Rastreo y localización de vehículos particulares.</li>
                <li>Rastreo y localización de vehículos de transporte de cargas.</li>
                <li>Rastreo y localización de maquinaria pesada.</li>
                <li>Rastreo y localización de vehículos de transporte de personas.</li>
                <li>Rastreo y localización de cargas.</li>
                <li>Rastreo y localización de mascotas</li>
                <li>Gestión de uso eficiente de vehículos empresariales.</li>
                <li>Comunicación oportuna de eventos controlados.</li>
                <li>Informes regulares de movimientos y eventos.</li>
              </ul>

              <span className="btn btn-red mt-2">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el GPS - Rastreo y Localización 24/7"} target="_blank" rel="noreferrer">
                  Solicitar este paquete
                </a>
              </span>
            </div>
          </div>

          <div className="img-grid">
            <img src={Rastreo} alt="" />
            <img src={Monitoreo} alt="" />
            {/* <div className="grid-top">
              <div>
              </div>
            </div>
            <div className="grid-bottom">
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiciosAuxiliares;
