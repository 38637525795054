export const products = [
  {
    id: 1,
    img: require("./img/Catalogo/camara-ip.jpg"),
    nombre: "Camara IP Bala 4Mp",
    descripcion: [
      {
        id: 1,
        especificacion: "Lente motorizado de 2.7mm a 13.5mm IR 60M",
      },
      {
        id: 2,
        especificacion: "WDR 120dB",
      },
      {
        id: 3,
        especificacion: "Starlight",
      },
      {
        id: 4,
        especificacion: "IVS",
      },
      {
        id: 5,
        especificacion: "Ranura Micro SD",
      }
    ],
    marca: "Dahua",
    tags: ["camara", "cctv"],
  },
  {
    id: 2,
    img: require("./img/Catalogo/camara-varifocal.jpg"),
    nombre: "Camara 2Mp",
    descripcion: [
      {
        id: 1,
        especificacion: "HDCVI Varifocal IR 40M",
      },
    ],
    marca: "Dahua",
    tags: ["camara", "cctv"]
  },
  {
    id: 3,
    img: require("./img/Catalogo/camara-bala-motorizado.jpg"),
    nombre: "Camara Bala 4Mp Lente Motorizado",
    descripcion: [
      {
        id: 1,
        especificacion: "Lente motorizado de 2.7mm a 13.5mm IR 60M - WDR",
      },
    ],
    marca: "Dahua",
    tags: ["camara", "cctv"],
  },
  {
    id: 4,
    img: require("./img/Catalogo/ballun-camara-color.jpg"),
    nombre: "Balun para camaras",
    descripcion: [
      {
        id: 1,
        especificacion: "HDCVI 200 mts a color x 2 unidades",
      },
    ],
    marca: "Dahua",
    tags: ["camara", "cctv"],
  },
  {
    id: 5,
    img: require("./img/Catalogo/ballun-rackeable.jpg"),
    nombre: "Balun Rackeable Metalico",
    descripcion: [
      {
        id: 1,
        especificacion: "Balun Rackeable Metalico para 16 CH para HDCVI",
      },
    ],
    marca: "Dahua",
    tags: ["camara", "cctv"],
  },
  {
    id: 6,
    img: require("./img/Catalogo/camara-domo.jpg"),
    nombre: "Camara Domo PTZ de 2Mp",
    descripcion: [
      {
        id: 1,
        especificacion: "5 pulgadas 25X zoom optico",
      },
    ],
    marca: "Dahua",
    tags: ["camara", "cctv"],
  },
  {
    id: 7,
    img: require("./img/Catalogo/switch-4puertos.jpg"),
    nombre: "Switch de 4 Puertos POE y 4 Puertos Uplink",
    descripcion: [],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 8,
    img: require("./img/Catalogo/switch-4puertos-frontal.jpg"),
    nombre: "Switch de 4 Puertos POE",
    descripcion: [],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 9,
    img: require("./img/Catalogo/switch-16puertos.jpg"),
    nombre: "Switch de 16 Puertos POE",
    descripcion: [],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 10,
    img: require("./img/Catalogo/hdd-10tb.jpg"),
    nombre: "Disco Duro 10TB Enterprise Seagate",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad: 10TB",
      },
    ],
    marca: "Seagate",
    tags: ["cctv"],
  },
  {
    id: 11,
    img: require("./img/Catalogo/hdd-1tb.jpg"),
    nombre: "Disco Duro 1TB",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad: 1TB",
      },
    ],
    marca: "Seagate",
    tags: ["cctv"],
  },
  {
    id: 12,
    img: require("./img/Catalogo/hdd-2tb.jpg"),
    nombre: "Disco Duro de Video Vigilancia",
    descripcion: [
      {
        id: 1,
        especificacion: "2TB, 5400RPM, 6GBS",
      },
    ],
    marca: "Western Digital",
    tags: ["cctv"],
  },
  {
    id: 13,
    img: require("./img/Catalogo/grabador-32canales.png"),
    nombre: "Grabador 32 Canales - 5Mp",
    descripcion: [
      {
        id: 1,
        especificacion: "32 Canales, 5 Mega Pixeles, N/1080p, Wizsense",
      },
    ],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 14,
    img: require("./img/Catalogo/grabador-24canales.png"),
    nombre: "Grabador IP 24 Canales 4K",
    descripcion: [
      {
        id: 1,
        especificacion: "24 Canales, 4K, 24 Puertos POE, Ancho de Banda 320Mbps, Soporta camaras de 12Mp",
      },
    ],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 15,
    img: require("./img/Catalogo/grabador-movil-8canales.jpg"),
    nombre: "Grabador Movil de 8 Canales CVI y Analogico",
    descripcion: [],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 16,
    img: require("./img/Catalogo/fuente-rejilla.jpg"),
    nombre: "Fuente Centralizada con Rejilla",
    descripcion: [{
      id: 1,
      especificacion: "12 VDC 4.20 Amp REAES DAHUA",
    }],
    marca: "Dahua",
    tags: ["energia"],
  },
  {
    id: 17,
    img: require("./img/Catalogo/fuente-exterior.jpg"),
    nombre: "Fuente Regulada con Caja para Exterior",
    descripcion: [{
      id: 1,
      especificacion: "12VDC 2Amp",
    }],
    marca: "Dahua",
    tags: ["energia"],
  },
  {
    id: 18,
    img: require("./img/Catalogo/gabinete-fuente.jpg"),
    nombre: "Central de Acceso para 4 Puertas con Gabinete y Fuente",
    descripcion: [
      {
        id: 1,
        especificacion: "Controlador de control de acceso para 4 puertas",
      },
      {
        id: 2,
        especificacion: "Soporta 100.000 tarjetas",
      },
      {
        id: 3,
        especificacion: "Registra 300.000 eventos",
      },
      {
        id: 4,
        especificacion: "Registra 300.000 eventos",
      },
      {
        id: 5,
        especificacion: "Antipassback, bloqueo múltiple, desbloqueo multi tarjeta, 4 sensores de puerta, 4 botones de salida, 1 entrada de alarma, 4 cerraduras, 1 salida de alarmas, 4 lectores",
      },
      {
        id: 6,
        especificacion: "Conexión TCP/IP, RS485, Wiegand",
      },
      {
        id: 7,
        especificacion: "Alimentación: 12VDC",
      },
      {
        id: 8,
        especificacion: "Software compatible SMARTPSS AC",
      },
    ],
    marca: "Dahua",
    tags: ["acceso"],
  },
  {
    id: 19,
    img: require("./img/Catalogo/monitor-32-dahua.jpg"),
    nombre: "Monitor de 32 Pulgadas CCTV",
    descripcion: [
      {
        id: 1,
        especificacion: "Carcasa de plástico ligera y rentable",
      },
      {
        id: 2,
        especificacion:
          "Angulo de visión extra ancho de 178ºH /178ºV para un rendimiento de visualización general",
      },
      {
        id: 3,
        especificacion:
          "Procesamiento digital de alta fidelidad para un video brillante y vívido",
      },
      {
        id: 4,
        especificacion:
          "Tiempo de respuesta rápido de 8ms sin imágenes borrosas o fantasma",
      },
      {
        id: 5,
        especificacion:
          "Admite múltiples entradas de señal como HDMI, VGA y USB",
      },
      {
        id: 6,
        especificacion: "Altavoces incorporados",
      },
      {
        id: 7,
        especificacion:
          "Botón físico y modo de control dual remoto por infrarrojos",
      },
      {
        id: 8,
        especificacion: "Bajo consumo de energía, larga vida útil",
      },
    ],
    marca: "DAHUA",
    tags: ["cctv"],
  },
  {
    id: 20,
    img: require("./img/Catalogo/monitor-43-dahua.jpg"),
    nombre: "Monitor de 43 plg",
    descripcion: [
      {
        id: 1,
        especificacion: "Carcasa de plástico ligera y rentable",
      },
      {
        id: 2,
        especificacion:
          "Angulo de visión extra ancho de 178ºH /178ºV para un rendimiento de visualización general",
      },
      {
        id: 3,
        especificacion:
          "Procesamiento digital de alta fidelidad para un video brillante y vívido",
      },
      {
        id: 4,
        especificacion:
          "Tiempo de respuesta rápido de 8ms sin imágenes borrosas o fantasma",
      },
      {
        id: 5,
        especificacion:
          "Admite múltiples entradas de señal como HDMI, VGA y USB",
      },
      {
        id: 6,
        especificacion: "Altavoces incorporados",
      },
      {
        id: 7,
        especificacion:
          "Botón físico y modo de control dual remoto por infrarrojos",
      },
    ],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 21,
    img: require("./img/Catalogo/monitor-4k-28.png"),
    nombre: "Monitor 4K 28 Pulgadas HDMI con Audio",
    descripcion: [
      {
        id: 1,
        especificacion: "Pantalla de alta resolución hasta 3840x2160",
      },
      {
        id: 2,
        especificacion: "Tecnología de panel VA",
      },
      {
        id: 3,
        especificacion: "Diseño de bisel ultra estrecho, apariencia elegante",
      },
      {
        id: 4,
        especificacion:
          "Angulo de visión extra amplio de 178ºH /178ºV para una visualización general rendimiento",
      },
      {
        id: 5,
        especificacion:
          "Procesamiento digital de alta fidelidad para un video brillante y vívido",
      },
      {
        id: 6,
        especificacion:
          "Admite mútilples enterfaces de señal, incluido HDMI 2.0 X2, DP 1.2 X1, USB X1, Altavoz X2",
      },
    ],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 22,
    img: require("./img/Catalogo/monitor-28.jpg"),
    nombre: "Monitor 28 Pulgadas",
    descripcion: [
      {
        id: 1,
        especificacion: "UHD para CCTV, especial para CCTV 24/7, Freesync",
      },
      {
        id: 2,
        especificacion:
          "Angulo de visión extra amplio de 178ºH /178ºV para un rendimiento de visualización general ",
      },
      {
        id: 3,
        especificacion:
          "Procesamiento digital de alta fidelidad para un video brillante y vívido",
      },
      {
        id: 4,
        especificacion:
          "Tiempo de respuesta rápido de 5ms sin imágenes borrosas o fantasma",
      },
      {
        id: 5,
        especificacion:
          "Admite mútilples enterfaces de señal, incluido HDMI, VGA y USB",
      },
      {
        id: 6,
        especificacion: "Altavoces incorporados",
      },
      {
        id: 7,
        especificacion: "Consumo de 40W",
      },
    ],
    marca: "Dahua",
    tags: ["cctv"],
  },
  {
    id: 23,
    img: require("./img/Catalogo/monitor-46.jpg"),
    nombre: "Monitor para CCTV de 46 pulgadas DAHUA",
    descripcion: [
      {
        id: 1,
        especificacion:
          "Monitor LCD PVA de nivel industrial, apto para uso externo 24/7",
      },
      {
        id: 2,
        especificacion:
          "Diseño de bisel a bisel ultra estrecho de 3.5mm (bisel de 2.3mm en los lados izquierdo y superior superior y bisel de 1.2mm a la derecha y lados inferiores)",
      },
      {
        id: 3,
        especificacion:
          "La alta relación de contraste y el alto brillo mejoran en gran medida las capas de video y presentar los detalles del video",
      },
      {
        id: 4,
        especificacion: "Filtro COMB 3D incorporado y reducción de ruido 3D",
      },
    ],
    marca: "DAHUA",
    tags: ["cctv"],
  },
  {
    id: 24,
    img: require("./img/Catalogo/teclado-tactil-azul.jpg"),
    nombre: "Teclado táctil LCD Azul y 8 particiones",
    descripcion: [
      {
        id: 1,
        especificacion: "Pantalla LCD de 32 caracteres y 8 particiones",
      },
      {
        id: 2,
        especificacion:
          "Permite editar etiquetas de zona, 1 entrada de zona en teclado",
      },
      {
        id: 3,
        especificacion: "Compatible con el panel  EVO192",
      },
    ],
    marca: "Paradox",
    tags: ["alarma"],
  },
  {
    id: 25,
    img: require("./img/Catalogo/teclado-botones.jpg"),
    nombre: "Teclado LED cableado",
    descripcion: [
      {
        id: 1,
        especificacion: "Iluminación de teclas patentada",
      },
      {
        id: 2,
        especificacion: "Compatible con MG5050 y la Serie SP",
      },
    ],
    marca: "",
    tags: ["alarma"],
  },
  {
    id: 26,
    img: require("./img/Catalogo/teclado-botones-lcd.jpg"),
    nombre: "Teclado LCD Azul de 32 caracteres y 8 particiones",
    descripcion: [
      {
        id: 1,
        especificacion:
          "Permite editar etiquetas de zona, usuarios y particiones",
      },
      {
        id: 2,
        especificacion: "Solo compatible con Panel EVO 192",
      },
    ],
    marca: "Paradox",
    tags: ["alarma"],
  },
  {
    id: 27,
    img: require("./img/Catalogo/sensor-movimiento-inalambrico.jpg"),
    nombre: "Sensor de movimiento inalámbrico",
    descripcion: [
      {
        id: 1,
        especificacion: "Frecuencia de trabajo (HZ) 433",
      },
      {
        id: 2,
        especificacion: "Distancia de tele detección 10m",
      },
      {
        id: 3,
        especificacion: "Rango de temperatura (ºC) 0 ~ 50",
      },
      {
        id: 4,
        especificacion: "Certificados CE & RoHS",
      },
      {
        id: 5,
        especificacion: "Compatible con BL-S2-Kit",
      },
      {
        id: 6,
        especificacion: "Distancia de funcionamiento de 50m",
      },
    ],
    marca: "",
    tags: ["sensor", "alarma"],
  },
  {
    id: 28,
    img: require("./img/Catalogo/sensor-movimiento-exterior.jpg"),
    nombre: "Sensor de movimiento para exteriores",
    descripcion: [
      {
        id: 1,
        especificacion: "Bajo techo",
      },
      {
        id: 2,
        especificacion: "Inmune a mascotas de 40Kg",
      },
      {
        id: 3,
        especificacion: "Optica dual",
      },
    ],
    marca: "",
    tags: ["sensor", "alarma"],
  },
  {
    id: 29,
    img: require("./img/Catalogo/sensor-movimiento-digital.jpg"),
    nombre: "Sensor de movimiento Digital",
    descripcion: [
      {
        id: 1,
        especificacion: "De montaje en techo",
      },
      {
        id: 2,
        especificacion: "Dos sensores de dos elementos",
      },
      {
        id: 3,
        especificacion: "Cobertura 360º",
      },
    ],
    marca: "",
    tags: ["sensor", "alarma"],
  },
  {
    id: 30,
    img: require("./img/Catalogo/sirena-exterior.jpg"),
    nombre: "Sirena para exterior de un solo tono",
    descripcion: [
      {
        id: 1,
        especificacion: "Alimentación de 12VDC",
      },
      {
        id: 2,
        especificacion: "15 Watts de potencia",
      },
      {
        id: 3,
        especificacion: "85Db",
      },
    ],
    marca: "",
    tags: ["alarma"],
  },
  {
    id: 31,
    img: require("./img/Catalogo/sirena-cuadrada.jpg"),
    nombre: "Sirena de dos tonos cuadrada",
    descripcion: [
      {
        id: 1,
        especificacion: "108Db",
      },
      {
        id: 2,
        especificacion: "Consumo de 50MA",
      },
    ],
    marca: "",
    tags: ["alarma"],
  },
  {
    id: 32,
    img: require("./img/Catalogo/luz-sentek.jpg"),
    nombre: "Luz Estroboscópica color rojo",
    descripcion: [
      {
        id: 1,
        especificacion: "Funciona a 12V",
      },
      {
        id: 2,
        especificacion: "Sin sonido",
      },
    ],
    marca: "Sentek",
    tags: ["alarma"],
  },
  {
    id: 33,
    img: require("./img/Catalogo/luz-dimax.jpg"),
    nombre: "Luz Estroboscópica",
    descripcion: [
      {
        id: 1,
        especificacion: "Color rojo",
      },
    ],
    marca: "Dimax",
    tags: ["alarma"],
  },
  {
    id: 34,
    img: require("./img/Catalogo/sirena-luz-dimax.jpg"),
    nombre: "Sirena con Luz Estroboscópica de 3 sonidos",
    descripcion: [
      {
        id: 1,
        especificacion: "",
      },
    ],
    marca: "Dimax",
    tags: ["alarma"],
  },
  {
    id: 35,
    img: require("./img/Catalogo/contacto-magnetico-honeywell.jpg"),
    nombre: "Contaco Magnético",
    descripcion: [
      {
        id: 1,
        especificacion: "",
      },
    ],
    marca: "Honeywell",
    tags: ["alarma"],
  },
  {
    id: 36,
    img: require("./img/Catalogo/contacto-magnetico-sentek.jpg"),
    nombre: "Contaco Magnético para Blindex color Blanco",
    descripcion: [],
    marca: "Sentek",
    tags: ["alarma"],
  },
  {
    id: 37,
    img: require("./img/Catalogo/contacto-magnetico-inalambrico.jpg"),
    nombre: "Contaco Magnético Inalámbrico de 2 Zonas",
    descripcion: [],
    marca: "Paradox",
    tags: ["alarma"],
  },
  {
    id: 38,
    img: require("./img/Catalogo/boton-panico-dahua.jpg"),
    nombre: "Botón de Pánico Inalámbrico",
    descripcion: [],
    marca: "Dahua",
    tags: ["alarma"],
  },
  {
    id: 40,
    img: require("./img/Catalogo/boton-emergencia-sentek.jpg"),
    nombre: "Botón Pulsador de Emergencia Inalámbrico",
    descripcion: [],
    marca: "Sentek",
    tags: ["alarma"],
  },
  {
    id: 40,
    img: require("./img/Catalogo/pulsador-panico-dimax.jpg"),
    nombre: "Pulsador Pánico de Pie",
    descripcion: [
      {
        id: 1,
        especificacion: "Acondicionamiento discreto en caso de emergencia",
      },
    ],
    marca: "Dimax",
    tags: ["alarma"],
  },
  {
    id: 41,
    img: require("./img/Catalogo/detector-humo-paradox.jpg"),
    nombre: "Detector de Humo Inalámbrico para Techo",
    descripcion: [],
    marca: "Paradox",
    tags: ["sensor", "alarma"],
  },
  {
    id: 42,
    img: require("./img/Catalogo/sensor-humo-sentek.jpg"),
    nombre: "Sensor de Humo de Tecnología Fototérmica",
    descripcion: [
      {
        id: 1,
        especificacion: "Sensor de Humo/Calor",
      },
      {
        id: 2,
        especificacion:
          "Se activa al detectar la presencia de Humo o cuando la Temperatura llega a los 57 ºC",
      },
      {
        id: 3,
        especificacion: "Base incluída",
      },
    ],
    marca: "Sentek",
    tags: ["sensor", "alarma"],
  },
  {
    id: 43,
    img: require("./img/Catalogo/sensor-humo-convencional-mircom.jpg"),
    nombre:
      "Sensor de Humo Foto Eléctrico Convencional para Ambientes Cerrados",
    descripcion: [],
    marca: "Mircom",
    tags: ["sensor", "alarma"],
  },
  {
    id: 44,
    img: require("./img/Catalogo/sensor-temperatura-fija.jpg"),
    nombre: "Sensor Convencional de Temperatura fija",
    descripcion: [
      {
        id: 1,
        especificacion: "Se activa cuando llega a 57ºC",
      },
    ],
    marca: "Mircom",
    tags: ["sensor", "alarma"],
  },
  {
    id: 45,
    img: require("./img/Catalogo/detector-humo-dimax.jpg"),
    nombre: "Detector de Humo Foto Eléctrico",
    descripcion: [],
    marca: "Dimax",
    tags: ["sensor", "alarma"]
  },
  {
    id: 46,
    img: require("./img/Catalogo/sensor-termico-cofem.jpg"),
    nombre: "Sensor Térmico Convencional",
    descripcion: [
      {
        id: 1,
        especificacion: "Actúa con la subida gradual de temperatura hasta 55ºC",
      },
    ],
    marca: "Cofem",
    tags: ["sensor", "alarma"]
  },
  {
    id: 47,
    img: require("./img/Catalogo/control-acceso-zkteco.jpg"),
    nombre: "Control de Acceso Standalone",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de huellas: 500",
      },
      {
        id: 2,
        especificacion: "Capacidad de tarjetas: 500",
      },
      {
        id: 3,
        especificacion: "Capacidad de registros: N/A",
      },
      {
        id: 4,
        especificacion: "Alimentación: 12V",
      },
    ],
    marca: "Zkteco",
    tags: ["acceso"],
  },
  {
    id: 48,
    img: require("./img/Catalogo/control-acceso-triangular-zkteco.jpg"),
    nombre: "Control de Acceso - Asistencia Facial",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de huellas: 3000",
      },
      {
        id: 2,
        especificacion: "Capacidad de tarjetas: 5000",
      },
      {
        id: 3,
        especificacion: "Capacidad de registros: 100.000",
      },
      {
        id: 1,
        especificacion: "Lector RFID",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 49,
    img: require("./img/Catalogo/control-acceso-green-zkteco.jpg"),
    nombre: "Control de Acceso - Asistencia Biométrico Green Label",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de huellas: 20.000",
      },
      {
        id: 2,
        especificacion: "Capacidad de tarjetas: 20.000",
      },
      {
        id: 3,
        especificacion: "Capacidad de registros: 200.000",
      },
      {
        id: 4,
        especificacion: "Interfaces COM, TCP/IP, USB",
      },
      {
        id: 5,
        especificacion: "Voltaje de Operación 12VDC",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 50,
    img: require("./img/Catalogo/sensor-optico-zkteco.jpg"),
    nombre: "Sensor Optico",
    descripcion: [
      {
        id: 1,
        especificacion: "Lector de Tarjeta SD",
      },
      {
        id: 2,
        especificacion: "Puertos de Entrada/Salida Wiegand",
      },
      {
        id: 3,
        especificacion: "Lector de proximidad de 125 KHz RFID",
      },
      {
        id: 4,
        especificacion: "Interruptor Anti sabotaje",
      },
    ],
    marca: "Zkteco",
    tags: ["acceso"],
  },
  {
    id: 51,
    img: require("./img/Catalogo/control-acceso-bluetooth.png"),
    nombre: "Control de Acceso Bluetooth",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de huellas: 1500",
      },
      {
        id: 2,
        especificacion: "Capacidad de tarjetas: 10.000",
      },
      {
        id: 3,
        especificacion: "Grado de Protección IP65",
      },
      {
        id: 4,
        especificacion:
          "Soporta comunicación vía Bluetooth para su gestión desde la aplicación en dispositivos móviles",
      },
      {
        id: 5,
        especificacion:
          "Control de acceso remoto, registro de información del usuario y configuración del dispositivo",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 52,
    img: require("./img/Catalogo/control-acceso-reconocimiento-facial.jpg"),
    nombre: "Control de Acceso por Reconocimiento Facial + Camara ONVIF",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de rostros: 400",
      },
      {
        id: 2,
        especificacion: "Capacidad de registros: 100.000",
      },
      {
        id: 3,
        especificacion: "Interfaces de comunicación: TCP/IP, conexión Wiegand",
      },
      {
        id: 4,
        especificacion: "Alimentación: 12VDC 2AMP",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 53,
    img: require("./img/Catalogo/candado-biometrico.jpg"),
    nombre: "Candado Biométrico",
    descripcion: [
      {
        id: 1,
        especificacion: "Material Aleación de Zinc, Color Negro",
      },
      {
        id: 2,
        especificacion: "Vida de la batería 1000 escaneos",
      },
      {
        id: 3,
        especificacion: "Capacidad de huellas: 10",
      },
      {
        id: 4,
        especificacion: "Dimensiones 20*53*53 mm",
      },
      {
        id: 5,
        especificacion: "Puerto de carga Micro USB",
      },
      {
        id: 6,
        especificacion: "Peso 0.2Kg",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 54,
    img: require("./img/Catalogo/control-acceso-medicion-temperatura.jpg"),
    nombre: "Control de Acceso con Medición de Temperatura",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de rostros: 3000",
      },
      {
        id: 2,
        especificacion: "Tecnología de Reconocimiento Facial Visible Light",
      },
      {
        id: 3,
        especificacion:
          "Medición de temperatura e identificación de cubrebocas",
      },
      {
        id: 4,
        especificacion: "Algoritmo facial anti falsificación ultrarápido",
      },
      {
        id: 5,
        especificacion:
          "Tecnología Computer Vision para reconocimiento de palma sin contacto",
      },
      {
        id: 6,
        especificacion:
          "Cámara con sensor CMOS starlight de 2Mp con función WDR",
      },
      {
        id: 7,
        especificacion:
          "Pantalla táctil de 8 Pulgadas con 400 Lux que ofrece alta visibilidad bajo luz fuerte y directa",
      },
      {
        id: 8,
        especificacion: "Para exteriores",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 55,
    img: require("./img/Catalogo/control-acceso-palma.jpg"),
    nombre: "Control de Acceso - Asistencia, Palma y Huella Digital",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de Palmas: 600",
      },
      {
        id: 2,
        especificacion: "Capacidad de huellas: 3000",
      },
      {
        id: 3,
        especificacion: "Capacidad de tarjetas: 10.000",
      },
      {
        id: 4,
        especificacion: "Capacidad de registros: 100.000",
      },
      {
        id: 5,
        especificacion: "Interfaces de conexión: TCP/IP, USB",
      },
      {
        id: 6,
        especificacion: "Voltaje 12V",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 56,
    img: require("./img/Catalogo/control-acceso-palma-wifi.jpg"),
    nombre: "Control de Acceso - Asistencia, Palma y Huella Digital WIFI",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad de Palmas: 600",
      },
      {
        id: 2,
        especificacion: "Capacidad de huellas: 3000",
      },
      {
        id: 3,
        especificacion: "Capacidad de tarjetas: 10.000",
      },
      {
        id: 4,
        especificacion: "Capacidad de registros: 100.000",
      },
      {
        id: 5,
        especificacion: "Diseño ergonómico y elegante",
      },
      {
        id: 6,
        especificacion: "Operación estable bajo fuentes de luz intensas",
      },
      {
        id: 5,
        especificacion: "Interfaces de comunicación: TCP/IP, USB, WiFi, 3G",
      },
      {
        id: 6,
        especificacion:
          "Funciones de acceso avanzadas, conexión con cerradura, alarma, entrada y salida Wiegand",
      },
    ],
    marca: "ZKteco",
    tags: ["acceso"],
  },
  {
    id: 57,
    img: require("./img/Catalogo/control-ronda-azul.jpg"),
    nombre: "Control de rondas",
    descripcion: [
      {
        id: 1,
        especificacion: "Cuerpo de metal, cubierta de caucho",
      },
      {
        id: 2,
        especificacion: "Dimensiones: 144*47*30mm",
      },
      {
        id: 3,
        especificacion: "Capacidad de almacenamiento: 60.000 registros",
      },
      {
        id: 4,
        especificacion: "Batería de Litio 3.7V de 880mA",
      },
    ],
    marca: "JWM",
    tags: ["seguridad"],
  },
  {
    id: 58,
    img: require("./img/Catalogo/control-ronda-naranja.jpg"),
    nombre: "Control de rondas",
    descripcion: [
      {
        id: 1,
        especificacion: "Cuerpo de metal, cubierta de caucho",
      },
      {
        id: 2,
        especificacion: "Dimensiones: 102*62*26 mm",
      },
      {
        id: 3,
        especificacion: "Capacidad de almacenamiento: 60.000 registros",
      },
      {
        id: 4,
        especificacion: "Batería de Litio 3.7V de 880mA",
      },
    ],
    marca: "JWM",
    tags: ["seguridad"],
  },
  {
    id: 59,
    img: require("./img/Catalogo/control-ronda-largo.jpg"),
    nombre: "Control de rondas",
    descripcion: [
      {
        id: 1,
        especificacion: "Cuerpo de metal, cubierta de caucho",
      },
      {
        id: 2,
        especificacion: "Dimensiones: 150*42*32 mm",
      },
      {
        id: 3,
        especificacion: "Capacidad de almacenamiento: 60.000 registros",
      },
      {
        id: 4,
        especificacion: "Batería de Litio 3.0V de 300mA",
      },
    ],
    marca: "JWM",
    tags: ["seguridad"],
  },
  {
    id: 60,
    img: require("./img/Catalogo/estabilizador-forza.jpg"),
    nombre: "Estabilizador Automático de Voltaje",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad 1000VA/500W",
      },
      {
        id: 2,
        especificacion: "Voltaje 220VAC",
      },
      {
        id: 3,
        especificacion: "4 Salidas universales",
      },
      {
        id: 4,
        especificacion: "Frecuencia 50-60Hz",
      },
      {
        id: 5,
        especificacion: "Indicador LED de estado",
      },
      {
        id: 6,
        especificacion: "Material termoplástico retardante de llama",
      },
      {
        id: 7,
        especificacion: "Orificios para montaje en pared",
      },
      {
        id: 8,
        especificacion:
          "Protección para la línea de teléfono, fax, módem, Internet y la red",
      },
      {
        id: 9,
        especificacion: "Termofusible de protección",
      },
    ],
    marca: "Forza",
    tags: ["energia"],
  },
  {
    id: 61,
    img: require("./img/Catalogo/regulador-voltaje-dimax.jpg"),
    nombre: "Regulador Automático de Voltaje",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad 1000VA",
      },
      {
        id: 2,
        especificacion: "Entrada 160-260 VAC",
      },
      {
        id: 3,
        especificacion: "Salidas 220 VAC",
      },
      {
        id: 4,
        especificacion: "50 Hz",
      },
      {
        id: 5,
        especificacion: "Forma de Onda Sinusoidal",
      },
    ],
    marca: "Dimax",
    tags: ["energia"],
  },
  {
    id: 62,
    img: require("./img/Catalogo/regulador-automatico-forza.jpg"),
    nombre: "Regulador Automático de Voltaje",
    descripcion: [
      {
        id: 1,
        especificacion: "Capacidad 3000VA/1500W",
      },
      {
        id: 2,
        especificacion: "Voltaje 220VAC",
      },
      {
        id: 3,
        especificacion: "4 Salidas universales",
      },
      {
        id: 4,
        especificacion: "Frecuencia 50-60Hz",
      },
      {
        id: 5,
        especificacion: "Indicador LED de estado",
      },
      {
        id: 6,
        especificacion: "Material termoplástico retardante de llama",
      },
      {
        id: 7,
        especificacion: "Orificios para montaje en pared",
      },
      {
        id: 8,
        especificacion:
          "Protección para la línea de teléfono, fax, módem, Internet y la red",
      },
      {
        id: 9,
        especificacion: "Termofusible de protección",
      },
      {
        id: 10,
        especificacion: "Temporizador de retardo 2seg/10seg/3min",
      },
    ],
    marca: "Forza",
    tags: ["energia"],
  },
  {
    id: 63,
    img: require("./img/Catalogo/router-tenda.jpg"),
    nombre: "Router Inalámbrico 150 Mbps",
    descripcion: [
      {
        id: 1,
        especificacion: "3G - 3.75G",
      },
      {
        id: 2,
        especificacion: "1 Antena externa",
      },
    ],
    marca: "Tenda",
    tags: ["red"],
  },
  {
    id: 64,
    img: require("./img/Catalogo/router-corporativo-intelbras.jpg"),
    nombre: "Router Inalámbrico Corporativo, Hot-spot 300",
    descripcion: [
      {
        id: 1,
        especificacion: "Soporta hasta 60 usuarios",
      },
      {
        id: 2,
        especificacion: "Check-ins facebook",
      },
    ],
    marca: "Intelbras",
    tags: ["red"],
  },
  {
    id: 65,
    img: require("./img/Catalogo/router-doble-banda-mercusys.jpg"),
    nombre: "Router Inalámbrico Doble Banda",
    descripcion: [
      {
        id: 1,
        especificacion: "AC 1200 867 Mbps/5GHz, 300Mbps/2.4Ghz",
      },
      {
        id: 2,
        especificacion: "4 Puertos Giga",
      },
      {
        id: 3,
        especificacion: "4 Antenas Fijas",
      },
      {
        id: 4,
        especificacion: "MIMO 2x2",
      },
    ],
    marca: "Mercusys",
    tags: ["red"],
  },
  {
    id: 66,
    img: require("./img/Catalogo/bateria-12v-18ah.jpg"),
    nombre: "Batería de 12V 8Ah",
    descripcion: [
      {
        id: 1,
        especificacion: "Sellado y libre de mantenimiento",
      },
      {
        id: 2,
        especificacion: "Sobrecarga protegida",
      },
      {
        id: 3,
        especificacion: "Construcción a prueba de fugas",
      },
      {
        id: 4,
        especificacion: "Caja Robusta y de alto impacto",
      },
    ],
    marca: "Honeybell",
    tags: ["energia"],
  },
  {
    id: 67,
    img: require("./img/Catalogo/bateria-12v-100ah.jpg"),
    nombre: "Batería Seca Recargable 12 V100A",
    descripcion: [
      {
        id: 1,
        especificacion: "12 V100A",
      },
      {
        id: 2,
        especificacion:
          "Ideal para UPS On-line, Sistemas de Respaldo y Sistemas Solares",
      },
      {
        id: 3,
        especificacion: "Bornera Redonda",
      },
    ],
    marca: "Dimax",
    tags: ["energia"],
  },
  {
    id: 68,
    img: require("./img/Catalogo/cerca-electrica-control-remoto.png"),
    nombre:
      "Cerca Eléctrica con Armado/Desarmado por Control Remoto y Control Mediante Aplicación Móvil",
    descripcion: [
      {
        id: 1,
        especificacion: "Voltaje de Salida 18.000V",
      },
      {
        id: 2,
        especificacion: "Longitud Máxima de 12.000m lineales",
      },
    ],
    marca: "JFL",
    tags: ["energia"],
  },
  {
    id: 69,
    img: require("./img/Catalogo/electrificador-llave.jpg"),
    nombre: "Electrificador a Llave (Solo Choque)",
    descripcion: [
      {
        id: 1,
        especificacion: "Tensión de Salida: 8000V +/- 10%",
      },
      {
        id: 2,
        especificacion: "Voltaje: 127/220 VAC 50Hz",
      },
      {
        id: 3,
        especificacion: "Distancia 1600m lineales",
      },
    ],
    marca: "JFL",
    tags: ["energia"],
  },
  {
    id: 70,
    img: require("./img/Catalogo/fmc130.png"),
    nombre: "FMC 130",
    descripcion: [
      {
        id: 1,
        especificacion: "Conexión confiable 4G (LTE Cat 1) con respaldo a redes 3G (UMTS), 2G (GSM)",
      },
      {
        id: 2,
        especificacion: "Configurable DIN/AIN para monitoreo y control remoto",
      },
      {
        id: 3,
        especificacion: "Lectura de datos CAN de vehículos y transportes especializados (con adaptador CAN)",
      },
      {
        id: 4,
        especificacion: "Batería de respaldo, permite que el dispositivo funcione sin una fuente de alimentación externa",
      },
      {
        id: 5,
        especificacion: "La entrada negativa permitirá una instalación fácil de los accesorios",
      },
      {
        id: 6,
        especificacion: "Entradas de impulso para lectura de datos de medidores de flujo de combustible",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 71,
    img: require("./img/Catalogo/fmu130.png"),
    nombre: "FMU 130",
    descripcion: [
      {
        id: 1,
        especificacion: "Conectividad GNSS y 3G",
      },
      {
        id: 2,
        especificacion: "Equipado con GNSS / Bluetooth y módulos 3G, GNSS interno, 3G antenas, digitales configurables",
      },
      {
        id: 3,
        especificacion: "Entradas analógicas y salidas digitales, entrada negativa, entradas de impulso",
      },
      {
        id: 4,
        especificacion: "Batería de respaldo, ermite que el dispositivo funcione sin una fuente de alimentación externa",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 72,
    img: require("./img/Catalogo/fmb920.png"),
    nombre: "FMB 920",
    descripcion: [
      {
        id: 1,
        especificacion: "Bluetooth para dispositivos externos y sensores BLE",
      },
      {
        id: 2,
        especificacion: "Detección de choque opera de acuerdo a los datos del acelerómetro",
      },
      {
        id: 3,
        especificacion: "Batería de respaldo, permite que el dispositivo funcione sin una fuente de alimentación externa",
      },
      {
        id: 4,
        especificacion: "Permite leer datos de bus CAN desde la ECU del vehículo a través de Bluetooth",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 73,
    img: require("./img/Catalogo/fmb965.jpg"),
    nombre: "FMB 965",
    descripcion: [
      {
        id: 1,
        especificacion: "Clasifica el grado de protección contra objetos sólidos y agua",
      },
      {
        id: 2,
        especificacion: "Bluetooth para dispositivos externos y sensores BLE",
      },
      {
        id: 3,
        especificacion: "Funcionalidad de detección de choque opera de acuerdo a los datos del acelerómetro",
      },
      {
        id: 4,
        especificacion: "Batería interna de gran capacidad (1200 mAh)",
      },
      {
        id: 5,
        especificacion: "Facilidad de aumentar el número de posibles modalidades de uso",
      },
      {
        id: 6,
        especificacion: "Apagar el modo de suspensión reduce el consumo de energía a menos de 1 mA",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 74,
    img: require("./img/Catalogo/fmb230.png"),
    nombre: "FMB 230",
    descripcion: [
      {
        id: 1,
        especificacion: "Clasifica el grado de protección contra objetos sólidos y agua",
      },
      {
        id: 2,
        especificacion: "DIN/AIN/NIN configurable para monitoreo y control remoto flexible",
      },
      {
        id: 3,
        especificacion: "Lectura de datos CAN de vehículos y transportes especializados (con adaptador CAN)",
      },
      {
        id: 4,
        especificacion: "Conexión confiable 4G (LTE Cat 1) con respaldo a la red 2G (GSM)",
      },
      {
        id: 5,
        especificacion: "Entradas de impulso para lectura de datos de medidores de flujo de combustible",
      },
      {
        id: 6,
        especificacion: "Batería de respaldo, permite que el dispositivo funcione sin una fuente de alimentación externa",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 75,
    img: require("./img/Catalogo/fmc225.png"),
    nombre: "FMC 225",
    descripcion: [
      {
        id: 1,
        especificacion: "Clasifica el grado de protección contra objetos sólidos y agua",
      },
      {
        id: 2,
        especificacion: "Bluetooth para dispositivos externos y sensores BLE",
      },
      {
        id: 3,
        especificacion: "Lectura de datos CAN de vehículos y transportes especializados (con adaptador CAN)",
      },
      {
        id: 4,
        especificacion: "Conexión confiable 4G (LTE Cat 1) con respaldo a la red 2G (GSM)",
      },
      {
        id: 5,
        especificacion: "Fiabilidad de red doble y selección de red múltiple",
      },
      {
        id: 6,
        especificacion: "Interfaces de comunicación serial RS232/RS485",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 76,
    img: require("./img/Catalogo/worker-badgep.png"),
    nombre: "WORKER BADGEP",
    descripcion: [
      {
        id: 1,
        especificacion: "Comunicación de voz bidireccional",
      },
      {
        id: 2,
        especificacion: "Diseño delgado para un uso diario cómodo",
      },
      {
        id: 3,
        especificacion: "Bluetooth para dispositivos externos y sensor de baja energía",
      },
      {
        id: 4,
        especificacion: "Escenarios de hombre caído, alarma y sin movimiento",
      },
      {
        id: 5,
        especificacion: "5 botones configurables",
      },
      {
        id: 6,
        especificacion: "Hasta 5 destinatarios de Llamadas/SMS en caso de emergencia",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 77,
    img: require("./img/Catalogo/mini-tracker.png"),
    nombre: "MINI TRACKER",
    descripcion: [
      {
        id: 1,
        especificacion: "Botón de alarma de fácil acceso, mensajería instantánea en caso de emergencia",
      },
      {
        id: 2,
        especificacion: "Alarma de emergencia automatizada para escenarios sin movimiento",
      },
      {
        id: 3,
        especificacion: "Sensores de baja potencia y soporte BLE Beacon para distintos casos de uso",
      },
      {
        id: 4,
        especificacion: "Dimensiones solo 44x43x20 mm. \n Se puede ocultar fácilmente y trabajar durante largas horas",
      },
      {
        id: 5,
        especificacion: "Monitoreo en tiempo real de la ubicación de personal dependiente",
      },
      {
        id: 6,
        especificacion: "WEB FOTA: administre y actualice sus dispositivos en un portal basado en la web",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 78,
    img: require("./img/Catalogo/asset-tracker.png"),
    nombre: "ASSET TRACKER EASY",
    descripcion: [
      {
        id: 1,
        especificacion: "1 informe por día - hasta 3 años",
      },
      {
        id: 2,
        especificacion: "Robusto, pequeño y fácil de instalar",
      },
      {
        id: 3,
        especificacion: "Resistente al agua y al polvo para integraciones en exteriores (IP68)",
      },
      {
        id: 4,
        especificacion: "Seguimiento de servicios basado en la ubicación",
      },
      {
        id: 5,
        especificacion: "Informes periódicos y programador semanal. \n Seguimiento en el lugar y en movimiento",
      },
      {
        id: 6,
        especificacion: "4 opciones de montaje",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
  {
    id: 79,
    img: require("./img/Catalogo/ble-asset-tracker.png"),
    nombre: "BLE ASSET TRACKER EASY",
    descripcion: [
      {
        id: 1,
        especificacion: "Mas de 3 años de duración de la batería mientras se trabaja en modo de comunicación BLE",
      },
      {
        id: 2,
        especificacion: "Robusto, pequeño y fácil de instalar",
      },
      {
        id: 3,
        especificacion: "Resistente al agua y al polvo para integraciones en exteriores (IP68)",
      },
      {
        id: 4,
        especificacion: "Seguimiento de servicios basado en la ubicación",
      },
      {
        id: 5,
        especificacion: "Envío de registros sincronizados y escenarios de activación",
      },
      {
        id: 6,
        especificacion: "Posibilidad de montaje magnético, correa y soporte adicional",
      },
    ],
    marca: "TELTONIKA",
    tags: ["gps"],
  },
];
