import React from "react";
import MonitoreoXs from "../img/Monitoreo/remoto-xs.webp";
import MonitoreoSm from "../img/Monitoreo/remoto-sm.webp";
import MonitoreoMd from "../img/Monitoreo/remoto-md.webp";
import MonitoreoLg from "../img/Monitoreo/remoto-lg.webp";
import MonitoreoXl from "../img/Monitoreo/remoto-xl.webp";
import Monitoreo from "../img/Monitoreo/remoto-xl.png";
import SeguridadTecnologia from "../img/Servicios/personal-tecnologia.png";
import TecnologiaTotal from "../img/Servicios/tecnologia-total.jpg";

const SeguridadControl = () => {
  return (
    <div className="main l-jic">
      <div className="servicios__intro swiper-slide polygon polygon-dark-bg">
        <div className="content-img">
          {/* <img src={Monitoreo} alt="" /> */}
          <picture>
            <source
              srcSet={`${MonitoreoXs} 320w, ${MonitoreoSm} 480w, ${MonitoreoMd} 768w, ${MonitoreoLg} 1200w, ${MonitoreoXl} 1920w`}
              type="image/webp"
            />
            <img src={Monitoreo} alt="" />
          </picture>
        </div>

        <div className="polygon-content  polygon-bg-dark">
          <div className="l-flex l-aic l-jcc l-fd-col">
            <h1>Seguridad y Control</h1>
            <p>Soluciones de Seguridad sostenibles y eficaces.</p>
          </div>
        </div>
      </div>

      <div className="text-description">
        <p>
          Análisis y diseño de servicios adecuados a requerimiento y
          vulnerabilidades observadas.
        </p>
        <p>
          Contar con personal idóneo, con capacitaciones constantes, asistidos
          con tecnología e informática, nos permite brindar servicios de
          seguridad y control eficaces y eficientes.
        </p>
      </div>

      <div className="section__title">
        <h2>Nuestras Opciones</h2>
      </div>

      <div className="servicios__container l-grid l-jic relative">
        <div className="servicios-seg-list l-grid l-mw-90">
          <div className="card-new  l-bg-light-gray">
            <div className="card-new__content content-left">
              <p>Tecnología total</p>
            </div>

            <div className="content-img card-new__content content-left l-pad-0 card-new-img">
              {/* <img src={Microchip} alt="" /> */}
              <img src={TecnologiaTotal} alt="" />
            </div>

            <div className="card-new__content card-new-ul-left">
              <p>Software de Gestión y Asistencia de Seguridad SIGAS.</p>
              <br />

              <p>Cuatro módulos de Usuarios con privilegios específicos.</p>
              <br />

              <p>Realizamos tareas de supervisión aleatorias.</p>
              <br />

              <p>Integración y gestión de equipos de control de acceso.</p>
              <br />

              <p>Integración y monitoreo de alarmas 24/7.</p>
              <br />

              <p>
                Integración y monitoreo remoto de cámaras de vigilancia.
              </p>
              <br />

              <p>
                Alerta temprana de situaciones de riesgo, accidente o
                incidente.
              </p>
              <br />

              <p>
                Asistencia de apoyo inmediato a situaciones de emergencias
                surgidas ¿Cuando?
              </p>
              <br />

              <p>Gestión de asistencia a la autoridad competente.</p>
              <br />

              <p>
                Informes rutinarios y específicos de acuerdo al requerimiento.
              </p>

              <span className="btn btn-red mt-2">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el producto Tecnología total"} target="_blank" rel="noreferrer">
                  Solicitar este producto
                </a>
              </span>
            </div>
          </div>

          <div className="card-new l-bg-light-gray">
            <div className="card-new__content content-right">
              <p>Personal de Seguridad + Tecnología</p>
            </div>

            <div className="content-img card-new__content content-right l-pad-0 card-new-img">
              {/* <img src={Monitoreo} alt="" /> */}
              <img src={SeguridadTecnologia} alt="" />
            </div>

            <div className="card-new__content card-new-ul">
              <p>
                Verificamos antecedentes policiales, laborales y domicilio de nuestro personal.
              </p>
              <br />

              <p>
                Asignamos personal capacitado en procedimientos de seguridad y
                control, prevención y extinción de incendios, soporte vital
                básico.
              </p>
              <br />

              <p>
                Supervisamos de manera permanente mediante supervisión física
                y monitoreo remoto de cámaras de seguridad.
              </p>
              <br />

              <p>
                Proveemos alimentación diaria a todo nuestro personal en cada
                puesto de servicio.
              </p>
              <br />

              <p>
                Respaldamos nuestros servicios con Póliza de Responsabilidad
                Civil de U$D 50.000
              </p>
              <br />

              <p>
                Aseguramos a nuestro personal con Póliza de Vida y accidentes.
              </p>
              <br />

              <p>Instalamos y monitoreamos equipos y sistemas de:</p>
              <ul>
                <li>Alarmas</li>
                <li>Cámaras de vigilancia</li>
                <li>Identificación y control de acceso</li>
              </ul>
              <span className="btn btn-red mt-2">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el producto Personal de Seguridad + Tecnología"} target="_blank" rel="noreferrer">
                  Solicitar este producto
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeguridadControl;
