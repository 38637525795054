import React from "react";
// import GestionWebXs from "../img/Gestion/gestion-xs.webp";
// import GestionWebSm from "../img/Gestion/gestion-sm.webp";
// import GestionWebMd from "../img/Gestion/gestion-md.webp";
// import GestionWebLg from "../img/Gestion/gestion-lg.webp";
// import GestionWebXl from "../img/Gestion/gestion-xl.webp";
// import Gestion from "../img/Gestion/gestion-lg.jpg";
import Desk from "../img/Gestion/desk.jpg";
import ConsultoriaWebXs from "../img/Gestion/consultoria-xs.webp";
import ConsultoriaWebSm from "../img/Gestion/consultoria-sm.webp";
import ConsultoriaWebMd from "../img/Gestion/consultoria-md.webp";
import ConsultoriaWebLg from "../img/Gestion/consultoria-lg.webp";
import ConsultoriaWebXl from "../img/Gestion/consultoria-xl.webp";
import Consultoria from "../img/Gestion/consultoria-xl.png";

const ServiciosConsultoria = () => {
  return (
    <div className="main l-jic">
      <div className="servicios__intro swiper-slide polygon polygon-dark-bg">
        <div className="content-img">
          {/* <img src={Monitoreo} alt="" /> */}
          {/* <picture>
            <source
              srcSet={`${GestionWebXs} 320w, ${GestionWebSm} 480w, ${GestionWebMd} 768w, ${GestionWebLg} 1200w, ${GestionWebXl} 1920w`}
              type="image/webp"
            />
            <img src={Gestion} alt="" />
          </picture> */}

          <picture>
            <source
              srcSet={`${ConsultoriaWebXs} 320w, ${ConsultoriaWebSm} 480w, ${ConsultoriaWebMd} 768w, ${ConsultoriaWebLg} 1200w, ${ConsultoriaWebXl} 1920w`}
              type="image/webp"
            />
            <img src={Consultoria} alt="" />
          </picture>
        </div>
        <div className="polygon-content polygon-bg-dark">
          <h1>
            Consultoría de Gestión de Riesgos y Soluciones de Seguridad
            Industrial
          </h1>
        </div>
      </div>

      <div className="text-description">
        <p>
          Ayudamos a nuestros clientes a reconocer sus riesgos y
          vulnerabilidades, identificar e implementar soluciones eficaces y
          eficientes que les permitan alcanzar sus objetivos de seguridad y
          control de manera sostenible.
        </p>
      </div>

      <div className="section__title">
        <h2>Nuestras Opciones</h2>
      </div>

      <div className="servicios__container l-grid l-jic relative l-bg-light-gray">
        <div className="servicios-aux-list l-grid l-mw-90">
          <div className="card">
            <div className="card__content l-flex l-fd-col h100">
              <div className="card-new-header">
                <p className="card-title-big">Opción A</p>
              </div>
              <div className="content-img">
                {/* <picture>
                  <source
                    srcSet={`${GestionWebXs} 320w, ${GestionWebSm} 480w, ${GestionWebMd} 768w, ${GestionWebLg} 1200w, ${GestionWebXl} 1920w`}
                    type="image/webp"
                  />
                  <img src={Consultoria} alt="" />
                </picture> */}
                <img src={Consultoria} alt="" />
              </div>

              <ul className="mt-1">
                <li>Análisis de riesgos y vulnerabilidades.</li>
                <li>
                  Análisis de estrategia, programa o plan de seguridad existente.
                </li>
                <li>Análisis de recursos existentes.</li>
                <li>
                  Análisis e identificación de nuevos recursos óptimos y mínimos
                  necesarios.
                </li>
                <li>
                  Planificación y elaboración de estrategia diseñada.
                </li>
              </ul>

              <span className="btn btn-red mt-auto">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el producto Servicios de Consultoría: Opción A"} target="_blank" rel="noreferrer">
                  Solicitar este producto
                </a>
              </span>
            </div>
          </div>

          <div className="card">
            <div className="card__content l-flex l-fd-col">
              <div className="card-new-header">
                <p className="card-title-big">Opción B</p>
              </div>
              <div className="content-img">
                <img src={Desk} alt="" />
                {/* <picture>
                  <source
                    srcSet={`${GestionWebXs} 320w, ${GestionWebSm} 480w, ${GestionWebMd} 768w, ${GestionWebLg} 1200w, ${GestionWebXl} 1920w`}
                    type="image/webp"
                  />
                  <img src={Gestion} alt="" />
                </picture> */}
              </div>

              <ul className="mt-1">
                <li>
                  Análisis de riesgos y vulnerabilidades.
                </li>
                <li>
                  Análisis de estrategia, programa o plan de servicio de seguridad existente.
                </li>
                <li>Análisis de recursos existentes.</li>
                <li>
                  Análisis e identificación de nuevos recursos óptimos y mínimos
                  necesarios.
                </li>
                <li>
                  Planificación y elaboración de estrategia de seguridad
                  integral e integrada.
                </li>
                <li>
                  Implementación, dirección y supervisión de estrategia diseñada.
                </li>
                <li>Evaluación de resultados - Feedback - Informe Final</li>
              </ul>
              <span className="btn btn-red mt-2">
                <a href={"https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el producto Servicios de Consultoría: Opción B"} target="_blank" rel="noreferrer">
                  Solicitar este producto
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiciosConsultoria;
