import React from "react";
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { BiMenu } from "react-icons/bi";
// import Logo from "../img/sigas-rojo.png";
import Logo from "../img/logo-rojo-sin-fondo.png";
// import LogoW from "../img/sigas-rojo.webp";
import LogoW from "../img/logo-rojo-sin-fondo.webp";

const NavBar = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisible = () => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 2500);
  };

  const hideMenu = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <header className="header scroll-header" id="header">
        <nav className="nav-bd-container">
          <Link to="/" className="nav__logo">
            <div className="l-aic">
              <img src={Logo} srcSet={LogoW} alt="" />
              <span><strong>BACKUP</strong></span>
            </div>
          </Link>

          <div
            id="nav-menu"
            className={isVisible ? "nav__menu show" : "nav__menu"}
          >
            <ul className="nav__list">
              <NavLink className="nav__link" to="/nosotros">
                Nosotros
              </NavLink>
              <NavLink className="nav__link" to="/servicios">
                Servicios
              </NavLink>
              <NavLink className="nav__link" to="/productos">
                Productos
              </NavLink>
              <NavLink className="nav__link" to="/contacto">
                Contacto
              </NavLink>
              {isVisible ? (
                <li className="nav__link link-cerrar" onClick={hideMenu}>
                  <span>
                    Cerrar
                  </span>
                </li>
              ) : null}
            </ul>
          </div>

          <div className="nav__icon" id="nav-icon" onClick={toggleVisible}>
            <BiMenu />
          </div>
        </nav>
      </header>
    </div>
  );
};

export default withRouter(NavBar);
