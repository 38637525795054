import React from "react";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";

const ProductCard = ({ props }) => {
  return (
    <div className="card relative">
      <div className="card__img content-img">
        {props.srcSet ? (
          <picture>
            <source
              srcSet={`${props.srcSet.xs} 320w, ${props.srcSet.sm} 480w, ${props.srcSet.md} 768w, ${props.srcSet.lg} 1200w, ${props.srcSet.xl} 1920w`}
              type="image/webp"
            />
            <img src={props.img} alt="" />
          </picture>
        ) : (
          <img src={props.img} alt="product card img" />
        )}
      </div>

      <div className="card__content">
        {props.nombre ? <p className="card-title">{props.nombre}</p> : null}
        {props.marca ? <p>{props.marca}</p> : null}

        <Link to={`/product-detail/${props.id}` || "/#"}>
          <span className="btn btn-clear mt-2">Ver más</span>
        </Link>

        <Link to={`/product-detail/${props.id}` || "/#"}>
          <div className="ver-mas">
            <p>Ver más</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
