import React from "react";
import { BiCurrentLocation, BiTime, BiPhone, BiMailSend } from "react-icons/bi";
import QrContacto from "../img/Contacto/qr-contacto.jpg";
import "aos/dist/aos.css";
import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";

const Contacto = (props) => {
  return (
    <div>
      <section className="contacto l-grid mt-5" id="contacto">
        <div className="contacto-container l-grid l-jic">
          <div className="contacto__list">
            <div className="contacto__direccion list__item">
              <div className="contacto__icon">
                <BiCurrentLocation />
              </div>
              <div className="ml-1">
                <h3>Nuestra dirección</h3>
                <p>
                  Calle Roger Mercado S/N, entre Celia Salmon y Franz Tamayo
                </p>
              </div>
            </div>

            <div className="contacto__horario list__item">
              <div className="contacto__icon">
                <BiTime />
              </div>
              <div className="ml-1">
                <h3>Horario de atención</h3>
                <p>
                  Lunes a viernes, de 8:30 a 12:30 - 14:30 a 18:30 y sábados de
                  8:30 a 12:30
                </p>
              </div>
            </div>

            <div className="contacto__telefono list__item">
              <div className="contacto__icon">
                <BiPhone />
              </div>

              <div className="ml-1">
                <h3>Teléfonos</h3>
                <p>+591 3427700</p>
                <p>+591 77040312</p>
              </div>
            </div>

            <div className="contacto__mail list__item">
              <div className="contacto__icon">
                <BiMailSend />
              </div>
              <div className="ml-1">
                <h3>Email</h3>
                <p>informacion@backup.com.bo</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            props.homePage
              ? "contacto-container l-grid l-jic contacto-superior"
              : "contacto-container l-grid l-jic"
          }
        >
          <div className="contacto-form dark-bg">
            <h2 className="section__title-emphasis">
              ¿Deseas adquirir nuestros servicios?
            </h2>

            <div className="l-flex inner-container">
              <form id="form" action="/" method="post" className="form">
                <input
                  type="text"
                  placeholder="Nombre"
                  name="firstName"
                  id="firstName"
                />
                <input
                  type="text"
                  placeholder="Apellido"
                  name="lastName"
                  id="lastName"
                />
                <div className="form-item">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                  />
                  <div className="form-buttons">
                    <button className="button-primary" type="submit">
                      Enviar mensaje
                    </button>
                    {/* <button className="button-green">
                      <a href={contactMessage} target="_blank" rel="noreferrer">
                        <BsWhatsapp />
                      </a>
                    </button> */}
                    <FloatingWhatsApp
                      zIndex={999999}
                      size="55px"
                      phone="+59177040312"
                      headerTitle="Bienvenido a Backup"
                      popupMessage="¿Cómo podemos ayudarte?"
                      message="Hola, quisiera saber más acerca de sus productos y servicios"
                    />
                  </div>
                </div>
              </form>
              <img
                src={QrContacto}
                alt="codigo qr con informacion de contacto"
                className="qr"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contacto;
