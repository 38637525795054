import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperButtons from './SwiperButtons';
import Alerta from "../img/Home/Alerta.png";
import Consultoria from "../img/Home/Consultoria.png";
import Excelencia from "../img/Home/Excelencia.png";
import Soluciones from "../img/Home/Soluciones.png";
import Respaldo from "../img/Home/Respaldo.png";
import LogoRojo from "../img/logo-rojo-sin-fondo.png";
// import LogoGris from "../img/logo-gris-sin-fondo.png";

// Import Swiper styles
import "swiper/css/bundle";
SwiperCore.use([Pagination, Navigation]);

const HomeSwiper = () => {
  return (
    <Swiper navigation className="relative">
      {/* Excelencia y honestidad */}
      <SwiperSlide className="polygon">
        <div className="content-img">
          {/* <picture>
            <source
              srcSet={`${Cars} 320w, ${CarsSm} 480w, ${CarsMd} 768w, ${CarsLg} 1200w`}
              type="image/webp"
            />
            <img src={CarsDefault} alt="Vehiculo de vigilancia" />
          </picture> */}
          <img src={Excelencia} alt="Vehiculo de vigilancia" />
        </div>

        <div className="polygon-content polygon-bg">
          <div className="polygon-content-title">
            <img src={LogoRojo} alt="" />
            <h1 className="l-text-dark">BACKUP <span>srl</span></h1>
          </div>
          <div className="l-flex l-aic l-jcc l-fd-col">
            <p className="l-text-dark">Excelencia y Honestidad</p>
            <div className="sigas-alt">
              <p>Seguridad Integral e Inteligente</p>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Respaldo */}
      <SwiperSlide className="polygon polygon-dark-bg">
        <div className="content-img">
          {/* <picture>
            <source
              srcSet={`${Guardia} 320w, ${GuardiaSm} 480w, ${GuardiaMd} 768w, ${GuardiaLg} 1200w, ${GuardiaXl} 1920w`}
              type="image/webp"
            />
            <img src={GuardiaDefault} alt="Vehiculo de vigilancia" />
          </picture> */}
          <img src={Respaldo} alt="Vehiculo de vigilancia" />
        </div>

        <div className="polygon-content respaldo-content polygon-bg-dark">
          <div className="polygon-content-title">
            <img src={LogoRojo} alt="" />
            <h1>Backup es Respaldo</h1>
          </div>
          <div className="l-flex l-aic l-jcc l-fd-col">
            <ul>
              <li>Profesionales,</li>
              <li>Capacidad,</li>
              <li>Experiencia,</li>
              <li>Inteligencia y</li>
              <li>Tecnología a tu servicio</li>
            </ul>
          </div>
        </div>
      </SwiperSlide>

      {/* Alerta temprana y monitoreo*/}
      <SwiperSlide className="polygon polygon-dark-bg">
        <div className="content-img">
          {/* <picture>
            <source
              srcSet={`${MonitoreoXs} 320w, ${MonitoreoSm} 480w, ${MonitoreoMd} 768w, ${MonitoreoLg} 1200w, ${MonitoreoXl} 1920w`}
              type="image/webp"
            />
            <img src={MonitoreoDefault} alt="Monitoreo en tiempo real" />
          </picture> */}
          <img src={Alerta} alt="Monitoreo en tiempo real" />
        </div>

        <div className="polygon-content polygon-bg-dark">
          <div className="polygon-content-title">
            <img src={LogoRojo} alt="" />
            <h1>BACKUP</h1>
          </div>
          {/* <p><span className="red-span">Seguridad Integral e Inteligente</span></p> */}
          <div className="l-flex l-aic l-jcc l-fd-col">
            <ul>
              <li>Alerta temprana y respuesta inmediata</li>
            </ul>
            <div className="sigas-alt">
              <p>Seguridad Integral e Inteligente</p>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Sigas */}
      <SwiperSlide className="polygon">
        <div className="content-img">
          {/* <picture>
            <source
              srcSet={`${Qr} 320w, ${QrSm} 480w, ${QrMd} 768w, ${QrLg} 1200w, ${QrXl} 1920w`}
              type="image/webp"
            />
            <img src={QrDefault} alt="Monitoreo en tiempo real" />
          </picture> */}
          <img src={Soluciones} alt="Monitoreo en tiempo real" />
        </div>

        <div className="polygon-content polygon-bg">
          <div className="polygon-content-title">
            <img src={LogoRojo} alt="" />
            <h1 className="l-text-dark">BACKUP</h1>
          </div>

          <div className="l-flex l-aic l-jcc l-fd-col">
            <p className="l-text-dark">Soluciones de seguridad</p>
            <Link to="/sigas">
              <div className="l-flex l-aic l-jcc">
                <div className="sigas-alt">
                  <p><span>SIGAS - Informática integrada</span></p>
                  <p><span>Software by Backup</span></p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </SwiperSlide>

      {/* Consultoria */}
      <SwiperSlide className="polygon polygon-dark-bg">
        <div className="content-img">
          {/* <picture>
            <source
              srcSet={`${Qr} 320w, ${QrSm} 480w, ${QrMd} 768w, ${QrLg} 1200w, ${QrXl} 1920w`}
              type="image/webp"
            />
            <img src={QrDefault} alt="Monitoreo en tiempo real" />
          </picture> */}
          <img src={Consultoria} alt="Monitoreo en tiempo real" />
        </div>

        <div className="polygon-content polygon-bg-dark">
          <div className="polygon-content-title">
            <img src={LogoRojo} alt="" />
            <h1>BACKUP</h1>
          </div>
          <div className="l-flex l-aic l-jcc">
            <div className="sigas-alt">
              <p>Consultoría de Gestión de Riesgos</p>
              <p>Soluciones de Seguridad Integral</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperButtons />
    </Swiper>
  );
};

export default HomeSwiper;
