import React from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="footer l-grid l-jic">
      <div className="l-grid repeat3 l-mw-90">
        <ul className="footer__list ">
          <li className="footer__item">
            <Link to="/">Backup</Link>
          </li>
          <li className="footer__item">
            <Link to="/nosotros">Nosotros</Link>
          </li>
          <li className="footer__item">
            <Link to="/contacto">Contacto</Link>
          </li>
        </ul>
        <ul className="footer__list ">
          <li className="footer__item">
            <Link to="/servicios">Servicios</Link>
          </li>
          <li className="footer__item">
            <Link to="/seg-control">Seguridad y Control</Link>
          </li>
          <li className="footer__item">
            <Link to="/serv-consultoria">
              Consultoría y Gestión de Seguridad
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/serv-auxiliar">
              Servicios Auxiliares de Seguridad y Control
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/gps">
              Servicio de Rastreo y Localización - GPS
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/sigas">SIGAS</Link>
          </li>
        </ul>
        <ul className="footer__list ">
          <li className="footer__item">
            <Link to="/productos">Productos</Link>
          </li>
          <li className="footer__item">
            <Link to="/productos">Cámaras de seguridad</Link>
          </li>
          <li className="footer__item">
            <Link to="/productos">Alarmas contra intrusión</Link>
          </li>
          <li className="footer__item">
            <Link to="/productos">Control de acceso</Link>
          </li>
          <li className="footer__item">
            <Link to="/productos">GPS</Link>
          </li>
        </ul>

        <div className="l-grid repeat3 rrss">
          <span className="footer__item">
            <Link to="/">Backup S.R.L. 2022</Link>
          </span>

          <div className="rrss-icons">
            <span>
              <Link to="/">
                <BsFacebook />
              </Link>
            </span>
            <span>
              <Link to="/">
                <BsInstagram />
              </Link>
            </span>
          </div>

          <span className="rrss-icons">
            <a href="https://storyset.com/city">
              City illustrations by Storyset
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
