import React from "react";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";

const Card = ({ props }) => {
  return (
    <div className="card relative">
      <div className="card__img content-img">
        {props.srcSet ? (
          <picture>
            <source
              srcSet={`${props.srcSet.xs} 320w, ${props.srcSet.sm} 480w, ${props.srcSet.md} 768w, ${props.srcSet.lg} 1200w, ${props.srcSet.xl} 1920w`}
              type="image/webp"
            />
            <img src={props.img} alt="" />
          </picture>
        ) : (
          <img src={props.img} alt="card img" />
        )}
      </div>
      {props.text ? (
        <div className="card__content">
          <p>{props.text}</p>
        </div>
      ) : null}

      {/* {props.descripcion ? (
        <div className="card__content">
          {props.descripcion.map((item, index) => {
            return <p key={index}>{item.especificacion}</p>;
          })}
        </div>
      ) : null} */}

      <div className="card__content">
        <p className="card-title">{props.title}</p>
        <Link to={props.path || "/#"}>
          <span className="btn btn-clear mt-2">Ver más</span>
        </Link>
      </div>
      <Link to={props.path || "/#"}>
        <div className="ver-mas">
          <p>Ver más</p>
        </div>
      </Link>
    </div>
  );
};

export default Card;
