import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { products } from "../dataList";

const Red = () => {
  const getData = () => {
    setListaProductos(products.filter((x) => x.tags.includes("energia") || x.tags.includes("red")));
  };

  const [listaProductos, setListaProductos] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="main">
      <section className="servicios relative mt-5">
        <div className="l-pad-2">
          <h2>Equipos y sistemas para Seguridad Eléctrica y accesorios de Red</h2>
        </div>

        <div className="servicios__container l-mw-90 l-grid repeat4">
          {listaProductos.map((item, index) => {
            return <ProductCard props={item} key={index} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default Red;
