import React from "react";
import Capacitacion from "../img/Capacitacion.jpg";
import Frame47 from "../img/Frame 47.png";
import Brujula from "../img/Nosotros/brujula.png";
import FourLife from "../img/Cliente/4life.png";
import LasPalmas from "../img/Cliente/alto-las-palmas.png";
import CaminoReal from "../img/Cliente/camino-real.svg";
import CopaAirlines from "../img/Cliente/copa.png";
import Datec from "../img/Cliente/datec.png";
import Floresta from "../img/Cliente/floresta.png";
import Ifa from "../img/Cliente/ifa.jpg";
import Imba from "../img/Cliente/imba.png";
import Praxis from "../img/Cliente/praxis.png";

// import { BiChart, BiLock, BiSpreadsheet, BiCheck, BiDevices } from "react-icons/bi";

const Nosotros = (props) => {
  return (
    <div
      className={!props.homePage ? "l-grid l-jic l-back-white" : "l-grid l-jic"}
    >
      {props.homePage ? (
        <div className="section__title">
          <h2>¿Quiénes somos?</h2>
        </div>
      ) : null}

      <div
        className={
          !props.homePage ? "nosotros l-mw-90 mt-5" : "nosotros l-mw-90"
        }
      >
        <div className="nosotros__container l-grid repeat2">
          <div className="content-intro">
            {props.homePage ? null : <h1>¿Quiénes somos?</h1>}
            <p className="section-description">
              Somos una empresa especializada en brindar soluciones de Seguridad
              Integral y Gestión de Riesgos
            </p>
            <div className="content-img mt-1">
              {/* <img
                src={Grupo}
                srcSet={
                  GrupoSm + " 300w, " + GrupoMd + " 350w, " + GrupoLg + " 400w"
                }
                alt=""
              /> */}
              <img src={Brujula} alt="compass" />
            </div>
          </div>

          <div className="content-mision l-flex l-fd-col l-jcc">
            <p className="section-subtitle">Nuestra misión</p>
            <p>Ser un equipo de apoyo incondicional para nuestros clientes.</p>
            <p>
              Brindarles Soluciones concretas de Seguridad y Control mediante
              estrategias personalizadas y provisión de Tecnología avanzada.
            </p>
            <p>
              Innovamos, nos capacitamos y superamos para asegurar la
              tranquilidad y confianza de las personas, su bienestar, su seguridad.
            </p>
            <p className="section-subtitle mt-2">Nuestra Visión</p>
            <p>
              Estar posicionados en el mercado nacional como la Empresa líder en
              servicios de excelencia en Seguridad Integral, Gestión de Riesgos
              y Provisión de Tecnología aplicada a la Protección y Control.
            </p>
          </div>

          {props.homePage ? null : (
            <>
              <div className="cifras grid-col-span-2">
                <div
                  className={
                    !props.homePage ? "cifra__item l-back-body" : "cifra__item"
                  }
                >
                  <p className="cifra__monto">+20</p>
                  <p className="cifra__texto">Años cuidando tu seguridad</p>
                </div>
                <div
                  className={
                    !props.homePage ? "cifra__item l-back-body" : "cifra__item"
                  }
                >
                  <p className="cifra__monto">+150</p>
                  <p className="cifra__texto">
                    Guardias de seguridad capacitados por año
                  </p>
                </div>
                <div
                  className={
                    !props.homePage ? "cifra__item l-back-body" : "cifra__item"
                  }
                >
                  <p className="cifra__monto">+19</p>
                  <p className="cifra__texto">
                    Módulos de capacitación impartidos a nuestro personal
                  </p>
                </div>
                <div
                  className={
                    !props.homePage ? "cifra__item l-back-body" : "cifra__item"
                  }
                >
                  <p className="cifra__monto">+90</p>
                  <p className="cifra__texto">Clientes satisfechos</p>
                </div>
              </div>

              <div className="nosotros__objetivos">
                <p className="section-subtitle">Nuestros objetivos</p>
                <p>
                  Apoyados en procesos de análisis y planificación, las
                  fortalezas de nuestro capital humano, y la utilización
                  inteligente de tecnología avanzada, estos son nuestros
                  objetivos de protección:
                </p>
                <ul className="l-text-primary">
                  <li>
                    Integridad física de las personas.
                  </li>
                  <li>
                    Bienes intangibles.
                  </li>
                  <li>
                    Bienes materiales.
                  </li>
                  <li>
                    Actividades rutinarias.
                  </li>
                </ul>
              </div>

              <div className="content-img">
                <img src={Capacitacion} alt="" />
              </div>

              <div className="content-img">
                <img src={Frame47} alt="" />
              </div>

              <div className="nosotros__unica">
                <p className="section-subtitle">Única empresa con profesionales certificados en:</p>
                <ul className="l-text-primary">
                  <li>Seguridad Consular</li>
                  <li>Seguridad VIP</li>
                  <li>Seguridad de Aviación Civil: OACI - DGAC</li>
                </ul>
              </div>
            </>
          )}
        </div>

        {props.homePage ? null : (
          <div>
            <p className="section-subtitle l-taic">Algunos de nuestros clientes</p>

            <div className="clientes-top l-jic l-aic">
              <img src={Praxis} alt="" />
              <img src={FourLife} alt="" />
              <img src={Imba} alt="" />
              <img src={CopaAirlines} alt="" />
            </div>

            <div className="clientes  l-jic l-aic">
              <img src={Ifa} alt="" />
              <img src={Datec} alt="" />
              <img src={Floresta} alt="" />
              <img src={CaminoReal} alt="" />
              <img src={LasPalmas} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Nosotros;
