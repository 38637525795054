import React from "react";
import LocationWeb from "../img/Location/location-web.jpg";
import Maintenance from "../img/ilustration/2840523.jpg";
import SearchingClip165 from "../img/ilustration/clip-1655.png";
import Truck from "../img/ilustration/5052660.jpg";
import ControlPanel from "../img/ilustration/Control Panel-rafiki.png";

const Gps = () => {
  return (
    <div className="l-grid l-jic">
      <section className="nosotros l-mw-90 mt-5">
        <div className="nosotros__container">
          <div className="content-intro">
            <h1>Servicio de Rastreo y Posicionamiento por GPS</h1>

            <div className="content-img relative">
              <img src={LocationWeb} alt="" />
            </div>
          </div>

          <div className="l-grid repeat2">
            <div className="content__mision">
              <span className="btn btn-red mb-2">
                <a
                  href="https://gsh40.net/id2"
                  target="_blank"
                  rel="noreferrer"
                  className="gps-link l-text-light"
                >
                  Prueba nuestra plataforma de rastreo y monitoreo por gps
                </a>
              </span>
              <p>
                Rastreo y posicionamiento en tiempo real de vehículos particulares, vehículos de transporte, maquinaria pesada, artículos
                de valor, cargas, personas, mascotas.
              </p>
              <br />
              <p>
                Implementación de geocercas, rutas y puntos de detención
                preestablecidos.
              </p>
              <br />
              <p>
                Control de cumplimiento de geocercas, rutas virtuales y paradas
                en destinos prestablecidos.
              </p>
            </div>

            <div className="content-img">
              <img src={SearchingClip165} alt="" />
            </div>
          </div>

          <div className="l-grid repeat2">
            <div className="content-img">
              <img src={Truck} alt="" />
            </div>
            <div className="nosotros__objetivos grid-superior">
              <p>
                Comunicación y alerta temprana a responsables de vehículos y/o
                conductores, por exceso de velocidad, abandono de ruta, abandono
                de geocerca, detención en lugar no autorizado, conducción
                peligrosa.
              </p>
              <br />
              <p>Seguimiento y reporte actualizado de ubicación de cargas.</p>
              <br />
              <p>
                Control y reporte de apertura de contenedores de cargas y
                camiones refrigerados.
              </p>
            </div>
          </div>

          <div className="l-grid repeat2">
            <div className="nosotros__objetivos">
              <p>Gestión de mantenimiento de vehículos.</p>
              <br />
              <p>
                Informes periódicos personalizados de historial de rutas,
                detenciones, conducción peligrosa y más acorde a
                requerimiento.
              </p>
            </div>

            <div className="content-img">
              <img src={Maintenance} alt="" />
            </div>
          </div>

          <div className="l-grid repeat2">
            <div className="content-img">
              <img src={ControlPanel} alt="" />
            </div>

            <div className="nosotros__objetivos grid-superior">
              <p>
                Asignación de usuario para auto gestión de rastreo y
                posicionamiento en tiempo real, revisión de historial de rutas,
                lugares de detenciones, conducta de manejo y más, configuración
                de notificaciones y alertas.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gps;
