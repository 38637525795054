import React from "react";
import Banner from "../img/banner.png";
import BannerWebXs from "../img/Sigas/banner-xs.webp";
import BannerWebSm from "../img/Sigas/banner-sm.webp";
import BannerWebMd from "../img/Sigas/banner-md.webp";
import BannerWebLg from "../img/Sigas/banner-lg.webp";
import BannerWebXl from "../img/Sigas/banner-xl.webp";
import Biometrico from "../img/Biometrico.jpg";
import BiometricoWebXs from "../img/Sigas/biometrico-xs.webp";
import BiometricoWebSm from "../img/Sigas/biometrico-sm.webp";
import BiometricoWebMd from "../img/Sigas/biometrico-md.webp";
import TeleworkWebXs from "../img/Sigas/telework-xs.webp";
import TeleworkWebSm from "../img/Sigas/telework-sm.webp";
import TeleworkWebMd from "../img/Sigas/telework-md.webp";
import ControlPanelWebXs from "../img/Sigas/control-panel-xs.webp";
import ControlPanelWebSm from "../img/Sigas/control-panel-sm.webp";
import ControlPanelWebMd from "../img/Sigas/control-panel-md.webp";
import QrInvitacionWebXs from "../img/Sigas/qr-invitacion-xs.webp";
import QrInvitacionWebSm from "../img/Sigas/qr-invitacion-sm.webp";
import QrInvitacionWebMd from "../img/Sigas/qr-invitacion-md.webp";
import EventosWebXs from "../img/Sigas/eventos-xs.webp";
import EventosWebSm from "../img/Sigas/eventos-sm.webp";
import EventosWebMd from "../img/Sigas/eventos-md.webp";
import ScannerWebXs from "../img/Sigas/scanner-xs.webp";
import ScannerWebSm from "../img/Sigas/scanner-sm.webp";
import ScannerWebMd from "../img/Sigas/scanner-md.webp";
import UserCardWebXs from "../img/Sigas/user-card-xs.webp";
import UserCardWebSm from "../img/Sigas/user-card-sm.webp";
import UserCardWebMd from "../img/Sigas/user-card-md.webp";
import Eventos from "../img/eventos.jpg";
import Scanner from "../img/scanner.png";
import QR from "../img/qr-invitacion.jpg";
import ControlPanel from "../img/ilustration/Control Panel-rafiki.png";
import UserCard from "../img/ilustration/user-card.png";
import Telework from "../img/ilustration/Telework-pana.png";

const Sigas = () => {
  return (
    <div className="l-grid l-jic">
      <div className="content-intro mt-5">
        <h2 className="l-pad-2">
          Software de Gestión y Asistencia de Seguridad
        </h2>
        <div className="content-img">
          <picture>
            <source
              srcSet={`${BannerWebXs} 320w,
                ${BannerWebSm} 480w, 
                ${BannerWebMd} 768w, 
                ${BannerWebLg} 1200w,
                ${BannerWebXl} 1920w`}
              type="image/webp"
            />
            <img src={Banner} alt="" />
          </picture>
        </div>
      </div>

      <section className="l-grid l-jcc l-mw-90 mv-5">
        <div className="l-grid repeat3">
          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${BiometricoWebXs} 320w, ${BiometricoWebSm} 480w, ${BiometricoWebMd} 768w`}
                  type="image/webp"
                />
                <img src={Biometrico} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">
                Integración con dispositivos de control de acceso
              </p>
              <p>
                Capacidad de integración y manejo de distintos dispositivos de
                identificación y control de acceso.
              </p>
            </div>
          </div>

          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${TeleworkWebXs} 320w,
                    ${TeleworkWebSm} 480w, 
                    ${TeleworkWebMd} 768w`}
                  type="image/webp"
                />
                <img src={Telework} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">Roles y usuarios</p>
              <p>
                Diferentes usuarios con roles y privilegios adecuados,
                residentes, administradores, operadores y guardías en portería.
              </p>
            </div>
          </div>

          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${UserCardWebXs} 320w,
                    ${UserCardWebSm} 480w, 
                    ${UserCardWebMd} 768w`}
                  type="image/webp"
                />
                <img src={UserCard} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">
                Control y registro automático de ingresos y salidas
              </p>
              <p>
                Con la integración de dispositivos de control de acceso permite
                llevar un registro digital y automático de ingresos y salidas de
                visitas y proveedores, personal de los distintos servicios,
                diarios o eventuales.
              </p>
            </div>
          </div>

          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${EventosWebXs} 320w,
                    ${EventosWebSm} 480w, 
                    ${EventosWebMd} 768w`}
                  type="image/webp"
                />
                <img src={Eventos} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">Privilegios de residentes</p>
              <p>
                El usuario residente puede enviar invitaciones con
                autorizaciones de ingreso mediante un código QR, individuales o
                grupales sus visitas o proveedores.
              </p>
            </div>
          </div>

          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${QrInvitacionWebXs} 320w,
                    ${QrInvitacionWebSm} 480w, 
                    ${QrInvitacionWebMd} 768w`}
                  type="image/webp"
                />
                <img src={QR} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">Autorizaciones de ingreso</p>
              <p>
                Autorizaciones de uso múltiple, con fecha y hora de inicio,
                fecha y hora de caducidad.
              </p>
              <p>
                Puede modificar o cancelar las fechas y horarios de las
                invitaciones y más.
              </p>
            </div>
          </div>

          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${ControlPanelWebXs} 320w, ${ControlPanelWebSm} 480w, ${ControlPanelWebMd} 768w`}
                  type="image/webp"
                />
                <img src={ControlPanel} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">
                Funciones y Privilegios del Administrador local
              </p>
              <p>Gestiona altas y bajas de los usuarios residentes.</p>
              <p>Suspende o habilita privilegios a los usuarios residentes.</p>
              <p className="section-subtitle"></p>
              <p>
                Gestiona autorizaciones de ingreso a personal de servicios
                eventuales o permanentes.
              </p>
              <p>
                Gestiona reportes de ingresos y salidas de vehículos y personas
                de servicios.
              </p>
            </div>
          </div>

          <div>
            <div className="content-img ">
              <picture className="card__img">
                <source
                  srcSet={`${ScannerWebXs} 320w,
                    ${ScannerWebSm} 480w, 
                    ${ScannerWebMd} 768w`}
                  type="image/webp"
                />
                <img src={Scanner} alt="" />
              </picture>
            </div>

            <div className="nosotros__objetivos mt-1">
              <p className="section-subtitle">
                Funciones y Privilegios del Guardia en portería
              </p>
              <p>
                Verifica la validez del código QR presentado por una visita o
                proveedor y facilita el ingreso.
              </p>
              <p>
                Completa el registro digital con los datos de la visita o
                proveedor y los datos del residente que lo autoriza.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sigas;
