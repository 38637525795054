import React from 'react'
import Servicios from './Servicios';
import Nosotros from './Nosotros';
import Contacto from './Contacto';
import HomeSwiper from './HomeSwiper';

const Home = () => {
    return (
        <React.Fragment>
            <HomeSwiper />
            <Servicios homePage={true} />
            <Nosotros homePage={true} />
            <Contacto homePage={true} />
        </React.Fragment>
    )
}

export default Home
