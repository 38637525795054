import React, { useState, useEffect } from "react";
import { products } from "../dataList";
import { useParams } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";

export const ProductDetail = () => {
  const [props, setProducto] = useState("");
  const { id } = useParams();
  const p = products.find((x) => x.id === parseInt(id));
  const [contactMessage, setContacMessage] = useState("");

  useEffect(() => {
    setProducto(p);
    setContacMessage(
      `https://api.whatsapp.com/send?phone=+59177040312&text=Me interesa el producto ${p.nombre}`
    );
  }, [p, id]);

  return (
    <div className="main">
      <section className="servicios relative mt-5 l-pad-1">
        <div className="card">
          <div className="card__img content-img l-pad-1">
            {props.srcSet ? (
              <picture>
                <source
                  srcSet={`${props.srcSet.xs} 320w, ${props.srcSet.sm} 480w, ${props.srcSet.md} 768w, ${props.srcSet.lg} 1200w, ${props.srcSet.xl} 1920w`}
                  type="image/webp"
                />
                <img src={props.img} alt="" />
              </picture>
            ) : (
              <img src={props.img} alt="product card img" />
            )}
          </div>

          <div className="card__content">
            {props.nombre ? (
              <p>
                <strong>{props.nombre}</strong>
              </p>
            ) : null}
            <p>Marca: {props.marca ? props.marca : null}</p>

            <ul>
              {props.descripcion
                ? props.descripcion.map((item, index) => {
                  return <li key={index}>{item.especificacion}</li>;
                })
                : null}
            </ul>

            <div className="l-taic">
              <button className="button-green l-pad-1 mt-1">
                <a href={contactMessage} target="_blank" rel="noreferrer">
                  Solicitar este producto
                  <BsWhatsapp className="mh-2" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
