import React from "react";
import Card from "./Card";
import GestionWebXs from "../img/Gestion/gestion-xs.webp";
import GestionWebSm from "../img/Gestion/gestion-sm.webp";
import GestionWebMd from "../img/Gestion/gestion-md.webp";
import GestionWebLg from "../img/Gestion/gestion-lg.webp";
import GestionWebXl from "../img/Gestion/gestion-xl.webp";
import Gestion from "../img/Gestion/gestion-lg.jpg";
import MonitoreoXs from "../img/Monitoreo/remoto-xs.webp";
import MonitoreoSm from "../img/Monitoreo/remoto-sm.webp";
import MonitoreoMd from "../img/Monitoreo/remoto-md.webp";
import MonitoreoLg from "../img/Monitoreo/remoto-lg.webp";
import MonitoreoXl from "../img/Monitoreo/remoto-xl.webp";
import Monitoreo from "../img/Monitoreo/remoto-xl.png";
import ServiciosWebXs from "../img/Servicios/servicios-xs.webp";
import ServiciosWebSm from "../img/Servicios/servicios-sm.webp";
import ServiciosWebMd from "../img/Servicios/servicios-md.webp";
import ServiciosWebLg from "../img/Servicios/servicios-lg.webp";
import ServiciosWebXl from "../img/Servicios/servicios-xl.webp";
import Servicios1 from "../img/Servicios/servicios-xl.png";
import ServSegControl from "../img/Servicios/guardia-revision-xl.jpg";
import ServSegControlWebXs from "../img/Servicios/guardia-revision-xs.webp";
import ServSegControlWebSm from "../img/Servicios/guardia-revision-sm.webp";
import ServSegControlWebMd from "../img/Servicios/guardia-revision-md.webp";
import ServSegControlWebLg from "../img/Servicios/guardia-revision-lg.webp";
import ServSegControlWebXl from "../img/Servicios/guardia-revision-xl.webp";
import "aos/dist/aos.css";

const Servicios = (props) => {
  const listaServicios = [
    {
      title: "Seguridad y control",
      text: "",
      path: "/seg-control",
      img: ServSegControl,
      srcSet: {
        xs: ServSegControlWebXs,
        sm: ServSegControlWebSm,
        md: ServSegControlWebMd,
        lg: ServSegControlWebLg,
        xl: ServSegControlWebXl,
      },
    },
    {
      title: "Consultoría y Gestión de Seguridad Integral",
      text: "",
      path: "/serv-consultoria",
      img: Gestion,
      srcSet: {
        xs: GestionWebXs,
        sm: GestionWebSm,
        md: GestionWebMd,
        lg: GestionWebLg,
        xl: GestionWebXl,
      },
    },
    {
      title: "Servicios Auxiliares de Seguridad y Control",
      text: "",
      path: "/serv-auxiliar",
      img: Monitoreo,
      srcSet: {
        xs: MonitoreoXs,
        sm: MonitoreoSm,
        md: MonitoreoMd,
        lg: MonitoreoLg,
        xl: MonitoreoXl,
      },
    },
  ];

  return (
    <div>
      <section className="servicios relative mt-5">
        {!props.homePage ? (
          <div className="servicios__intro swiper-slide polygon polygon-dark-bg">
            <div className="content-img">
              {/* <img src={Security} alt="" /> */}
              <picture>
                <source
                  srcSet={`${ServiciosWebXs} 320w, ${ServiciosWebSm} 480w, ${ServiciosWebMd} 768w, ${ServiciosWebLg} 1200w, ${ServiciosWebXl} 1920w`}
                  type="image/webp"
                />
                <img src={Servicios1} alt="" />
              </picture>
            </div>

            <div className="polygon-content polygon-bg-dark">
              <h1>Servicios inteligentes e innovadores</h1>
              <p>
                Elige el servicio apropiado para tu necesidad o solicita un
                servicio personalizado
              </p>
            </div>
          </div>
        ) : null}

        <div className="section__title">
          <h2>Nuestros servicios</h2>
        </div>

        <div className="servicios__container l-mw-90 l-grid repeat4">
          <div className="section-description">
            <p className="section-subtitle">
              Servicios Inteligentes e Innovadores
            </p>
            <ul>
              <li>Infórmate acerca de nuestros distintos servicios.</li>
              <li>Elige el servicio apropiado para tu necesidad o solicita un servicio personalizado.</li>
            </ul>
          </div>

          {listaServicios.map((item, index) => {
            return <Card props={item} key={index} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default Servicios;
