import React from "react";
import Camaras from "../img/camaras.png";
import Alarmas from "../img/alarmas.png";
import ControlAcceso from "../img/control-acceso.png";
import Rastreo from "../img/gps.png";
import EquiposSeguridad from "../img/EquiposSeguridad.png";
import Residente from "../img/InvitacionQR.png";
import RedCover from "../img/red-cover.png";
import Card from "./Card";

const Productos = () => {
  const listaProductos = [
    {
      title: "Cámaras de seguridad, equipos y sistemas de CCTV",
      text: "",
      path: "/cctv",
      img: Camaras,
    },
    {
      title: "Alarmas contra intrusión e incendios",
      text: "",
      path: "/alarmas",
      img: Alarmas,
    },
    {
      title: "Control de acceso, equipos y alarmas",
      text: "",
      path: "/acceso",
      img: ControlAcceso,
    },
    {
      title: `GPS \n Equipos y sistemas`,
      text: "",
      path: "/gps-productos",
      img: Rastreo,
    },
    {
      title: `Equipos para Seguridad Física`,
      text: "",
      path: "/seguridad",
      img: EquiposSeguridad,
    },
    {
      title: `Equipos y sistemas para  Seguridad Eléctrica y accesorios de Red`,
      text: "",
      path: "/red",
      img: RedCover,
    },
    {
      title: `SIGAS \n Sistema de Gestión y Asistencia de Seguridad`,
      text: "",
      path: "/sigas",
      img: Residente,
    },
  ];

  return (
    <div className="main">
      <section className="servicios relative mt-5">
        <div className="section__title">
          <h2>Nuestros Productos</h2>
        </div>

        <div className="servicios__container l-mw-90 l-grid repeat4">
          {listaProductos.map((item, index) => {
            return <Card props={item} key={index} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default Productos;
